<template>
    <div class="service-wrap bg-albastor pb-75">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                    <div class="section-title style1 text-center mb-40">
                        <!-- <span>Our Services</span> -->
                        <h2 class="header-blue-tf-color">Our Purpose</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                        <img src="../../assets/images/Purpose/heart.png" alt="Image">
                        </span>
                        <h6>PROMOTE DEVELOPMENT IN ARUBA</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/star.png" alt="Image">
                        </span>
                        <h6>PROVIDE EQUAL OPPORTUNITIES</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/persons.png" alt="Image">
                        </span>
                        <h6>CREATE EASY TO USE FINANCIAL PRODUCTS</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/paper.png" alt="Image">
                        </span>
                        <h6>HELP YOU TO ACHIEVE YOUR DREAMS</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>