<template>
    <div class="service-wrap bg-albastor pt-100 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                    <div class="section-title style1 text-center mb-40">
                        <!-- <span>Our Services</span> -->
                        <h2 class="header-blue-tf-color">Our Values</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                        <img src="../../assets/images/Purpose/trust.png" alt="Image">
                        </span>
                        <h6>TRUST</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/we-are-customer-driven.png" alt="Image">
                        </span>
                        <h6>WE ARE CUSTOMER DRIVEN</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/we-do-right-thing.png" alt="Image">
                        </span>
                        <h6>WE DO THE RIGHT THING</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/we-move-fast.png" alt="Image">
                        </span>
                        <h6>WE MOVE FAST</h6>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/Purpose/we-adapt-to-change.png" alt="Image">
                        </span>
                        <h6>WE ADAPT TO CHANGE</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnlineShopping'
}
</script>