<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">
                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Date of last revision and effective as of: November 22, 2022.</h3>
                        <p>
                            Mack’s Total Finance VBA (“Total Finance”, “us”, “we”, or “our”) operates www.totalfinancearuba.com, the site and the services provided by Total Finance shall hereinafter be referred to as: “Service”.
                        </p>
                        <p>
                            We use your data to provide and improve our Service. By using our Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions. Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“Agreement”).
                        </p>
                        <p>
                            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
                        </p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Information Collection and Use</h3>
                        <p>Total Finance collects several different types of information for various purposes to provide and improve our Service to you.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Personal Data</h3>
                        <p>The Personal Data is processed by Total Finance because it was provided by you on your own initiative, it was obtained in connection with the provision of Services, it was made known to Total Finance by third parties or became known through public sources.</p>
                        <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Usage Data</h3>
                        <p>Total Finance may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“Usage Data”).</p>
                        <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <p>When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Location Data</h3>
                        <p>Total Finance may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</p>
                        <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Tracking Cookies Data</h3>
                        <p>Total Finance uses cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                        <p>Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
                        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Use of Data</h3>
                        <p>Total Finance uses the collected data for various purposes:</p>
                        <p>
                            o to provide and maintain our Service; <br />
                            o to notify you about changes to our Service; <br />
                            o to allow you to participate in interactive features of our Service when you choose to do so; <br />
                            o to provide customer support; <br />
                            o to gather analysis or valuable information so that we can improve our Service; o to monitor the usage of our Service; <br />
                            o to detect, prevent and address technical issues; <br />
                            o to fulfill any other purpose for which you provide it; <br />
                            o to comply with regulatory and policy requirements; <br />
                            o to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection; <br />
                            o to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.; <br />
                            o to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already enquired about unless you have opted not to receive such information; o in any other way we may describe when you provide the information; o for any other purpose with your consent. <br />
                        </p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Retention of Data</h3>
                        <p>Total Finance will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                        <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Transfer of Data</h3>
                        <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your country or legal jurisdiction, where the data protection laws may differ from those of your jurisdiction.</p>
                        <p>If you are located outside Aruba and choose to provide information to us, please note that we transfer the data, including Personal Data, to Aruba and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Disclosure of Data</h3>
                        <p>Total Finance may disclose personal information that we collect, or you provide: under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities. If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>
                        <p>We may disclose your information also to our subsidiaries and affiliates, contractors, service providers, and other third parties we use to support our business and to fulfill the purpose for which you provide it.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Security of Data</h3>
                        <p>The security of your data is important to us, nevertheless, keep in mind that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Service Providers</h3>
                        <p>Total Finance may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.</p>
                        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. Total Finance may use third- party Service Providers to monitor and analyze the use of our Service. In addition, Total Finance may use third-party Service Providers to automate the development process of our Service.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Links to Other Sites</h3>
                        <p>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Children’s Privacy</h3>
                        <p>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
                        <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Changes to This Privacy Policy</h3>
                        <p>Total Finance may update our Privacy Policy from time to time. Updated Privacy Policy will be posted on this website and the date of last revision will be placed at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.</p>
                    </div>

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">Contact Us</h3>
                        <p>If you have any questions about this Privacy Policy, please contact us by email: info@total- finance.net.</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>