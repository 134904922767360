<template>
  <div class="service-wrap style3 ptb-100 bg-rock">
    <div class="container">
      <img
        src="../../assets/images/service-shape-1.png"
        alt="Image"
        class="service-shape-one"
      />
      <img
        src="../../assets/images/service-shape-2.png"
        alt="Image"
        class="service-shape-two"
      />
      <div class="section-title style1 text-center mb-40">
        <!-- <span>Why</span> -->
        <h2 class="text-white">Why TOTAL</h2>
      </div>

      <div class="row gx-5 align-items-center">
        
        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-user"></i>
            </span>
            <div class="service-info">
              <h3>
                <a href="javascript:void(0)">Accessible to everyone</a>
                <!-- <router-link to=""></router-link> -->
              </h3>
              <!-- <p>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-clock"></i>
            </span>
            <div class="service-info">
              <h3>
                <a href="javascript:void(0)">Easy application & Approval within 48 hours</a>
                <!-- <router-link to="/service-details"></router-link> -->
              </h3>
              <!-- <p>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-loan-1"></i>
            </span>
            <div class="service-info">
              <h3>
                <a href="javascript:void(0)">Attractive rates & easy payments</a>
                <!-- <router-link to="/service-details"></router-link> -->
              </h3>
              <!-- <p>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-computer"></i>
            </span>
            <div class="service-info">
              <h3>
                <a href="javascript:void(0)">Tailored Service</a>
                <!-- <router-link to="/service-details"></router-link> -->
              </h3>
              <!-- <p>
              </p> -->
            </div>
          </div>
        </div>

      </div>

      <!-- <div class="text-center mt-20">
        <router-link to="/services" class="btn style1">View All Services</router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
