<template>
    <div class="counter-wrap">
        <div class="container">
            <div class="counter-card-wrap">
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>3</span>
                        </div>
                        <p>Branches</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>34</span>
                        </div>
                        <p>Team Members</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>40+</span>
                        </div>
                        <p>Years in Finance</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>10000+</span>
                        </div>
                        <p>Successful Loans</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Counter'
}
</script>