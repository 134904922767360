<template>
    <div class="feature-wrap pt-100 pb-75 bg-albastor">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>Our Feature</span>
                <h2>Payment Services Worldwide</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style1">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-4.png" alt="Image"></span>
                                <h3>Protect Your Card</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style1">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-5.png" alt="Image"></span>
                                <h3>Send Money</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style1">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-6.png" alt="Image"></span>
                                <h3>Online Banking</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>