<template>
    <div class="testimonial-wrap ptb-100  bg-albastor">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center mb-40">
                        <span>Our Testimonials</span>
                        <h2 class="header-blue-tf-color">What Our Clients Say About Us</h2>
                    </div>
                </div>
            </div>
            <div class="testimonial-slider-three">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="testimonial-card style3">
                            <span class="quote-icon">
                                <i class="flaticon-quotation-mark"></i>
                            </span>
                            <p class="client-quote">{{slide.description}}</p>
                            <div class="testimonial-card-thumb">
                                <div class="client-info-wrap">
                                    <div class="client-img">
                                        <img :src="slide.image" alt="Image">
                                    </div>
                                    <div class="client-info">
                                        <h4 class="header-blue-tf-color">{{slide.name}}</h4>
                                        <span>{{slide.position}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='flaticon-next'></i>
                            </template>
                            <template #prev>
                                <i class='flaticon-left-arrow-1'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div> 
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                description: 'Mi por bisa di dia cu mi a bai total finance pa buska informashon e servicio tabata hopi bon.  Mi a haya tur splicacion necesario. Prome biaha mi a aplika tur e proceso ta bai bon y lihe. Mi por bisa cu total finance su proceso pa prestamo ta un di e mihor nan na Aruba. Pago mensual rasonabel. Mi a refinancia 2 biaha y tur cos a bai bon y lihe. Den esaki mi kier gradici Maryann cu mi ultimo proceso di refinance, mi por bisa cu e lo go the extra mile pa loke ta ayudo cu e proceso aki. No solamente ta wak e persona como djis un otro cliente, Maryann ta tuma tempo pa scucha y conseha pa asina jega na e miho decision pa su cliente. Sin lubida e comunicacion pa mantene cada update di e proceso. Sigur un danki na Maryann pa su tremendo trabou y tambe danki na total finance cu ta ofrece diferente opcion pa cliente.',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'S.S',
                position: 'Customer',
            },
            {
                id: 2,
                description: 'Mi experiencia cu Total Finance ta súper fácil, practico y liher. Aplica riba website y tur detaye ta wordo procesa y keda aproba y a haya e placa riba cuenta na final di dia. Awo si mi ta logra mi vakantie pa Europa facil y trankilo.',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'J.C',
                position: 'Customer',
            },
            {
                id: 3,
                description: 'As a new client, it was exceptionally easy to receive a loan from Total Finance. The requirements are few and can be easily met. The service I received from Total Finance was absolutely cordial and superb. I was assisted by Ms. Diana Franken who, from day one, very friendly welcomed me as a new potential client and worked very efficiently and extremely quickly in my loan application process. The team at Total Finance went out of their way to make sure I received the loan I desired. Therefore, I undoubtedly suggest to anyone in need of a quick loan that they do not hesitate in exploring the amazing and friendly services of Total Finance! I strongly recommend that you become the next new client of Total Finance.',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'R.R',
                position: 'Customer',
            },
            {
                id: 4,
                description: 'Nadine mi kier a bisa hopi hopi hopi danki pa e ayudo, e servicio tawata  master ela bay hopi liher , hopi bon mes, supel ningun problema, y mia ta hopi grateful pa e judanza y kon liher ela bay, appreciate a lot.',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'A.K',
                position: 'Customer',
            },
            {
                id: 5,
                description: 'Total Finance su calculator ta corecto pa e pago mensual, no lo tin sorpresa. A yuda hopi bon via whatsapp y a facilita tur cos, e unico cos personal cu a pasa ta ora di pasa firma pa bo risibo bo placa. Den menos cu 1 siman esei ta possible. Masha danki total finance.',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'G.R',
                position: 'Customer',
            },
            {
                id: 6,
                description: 'Mi ta orguyoso di ta cliente di Total Finance mas di 25 aña pa prestamo. Mi kier gradici empleado(nan) pa e bon servicio y prestamo rapido y facil. Keep up the good work!!',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'V. Ras',
                position: 'Customer',
            },
            {
                id: 7,
                description: 'Mi kier a bisa Danki na Michelline ku primeramente a yudami ku info y sigur guiami e kaminda pa asina jega na un leening ku boso nan y asina sigui pa Maryann ku amablemente a kaba di hinka tur kos den otro pa asina yudami jega na e leening ku mi persona tabata tin mester. Masha danki pa e servicio super gr8 di boso parti y sigur un servicio super liher. Masha danki atrobe. Saludos',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'A.W',
                position: 'Customer',
            },
            {
                id: 8,
                description: 'Bon tardi mi ker a gradisi Maryann pa bo servisio di ajera hopi nice mes un persona cu hopi pasenshi i ambel .danki',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'M.S',
                position: 'Customer',
            },
            {
                id: 9,
                description: "Boso formula di duna servicio ta top y boso rate ta razonabel. Maryann a atende cumi den un forma hopi rapido y profesional y semper cla pa contesta cualkier pregunta, danki pa'bo bon servicio Maryann. Ami por bisa cu mi ta satisfecho cu e servicio di Total Finance",
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'B.G',
                position: 'Customer',
            },
            {
                id: 10,
                description: 'Mi por bisa di dia cu mi a bai total finance pa buska informashon e servicio tabata hopi bon.  Mi a haya tur splicacion necesario. Prome biaha mi a aplika tur e proceso ta bai bon y lihe. Mi por bisa cu total finance su proceso pa prestamo ta un di e mihor nan na Aruba. Pago mensual rasonabel. Mi a refinancia 2 biaha y tur cos a bai bon y lihe. Den esaki mi kier gradici Maryann cu mi ultimo proceso di refinance, mi por bisa cu e lo go the extra mile pa loke ta ayudo cu e proceso aki. No solamente ta wak e persona como djis un otro cliente, Maryann ta tuma tempo pa scucha y conseha pa asina jega na e miho decision pa su cliente. Sin lubida e comunicacion pa mantene cada update di e proceso. Sigur un danki na Maryann pa su tremendo trabou y tambe danki na total finance cu ta ofrece diferente opcion pa cliente.',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'S.S',
                position: 'Customer',
            },
            {
                id: 11,
                description: 'Mi experiencia cu Total Finance ta súper fácil, practico y liher. Aplica riba website y tur detaye ta wordo procesa y keda aproba y a haya e placa riba cuenta na final di dia. Awo si mi ta logra mi vakantie pa Europa facil y trankilo',
                image: require('../../assets/images/testimonials/generic-user.png'),
                name: 'J.C',
                position: 'Customer',
            },
        ],
    }),
})
</script>