<template>
    <div class="service-charge-wrap bg-stratos ptb-100" id="ChargeCalculator">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <!-- <span>Loan Form</span> -->
                <h2 style="color: #FFFFFF !important;">Loan Calculator</h2>
            </div>
            <form @submit.prevent class="charge-form">
                <div class="row justify-content-center">
                    <div class="col-lg-2 col-md-6 col-6">
                        <div class="form-group">
                            <label for="DDLTypeLoan">Loan Type</label>
                            <select id="DDLTypeLoan" name="DDLTypeLoan" v-model="LoanType" v-on:change="this.GetMinAndMaxAmount()" style="position: inherit !important; width: 157px !important;">
                                <option value="1">Personal</option>
                                <option value="2">Vehicle</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-6">
                        <div class="form-group">
                            <label for="DDLPeriod">Period in Months</label>
                            <select id="DDLPeriod" name="DDLPeriod" v-model="MonthSelectedOption" v-on:change="this.GetMonthlyPayment()" style="position: inherit !important; width: 157px !important;">
                                <option v-for="Month in MonthsArray" :value="Month">{{ Month }} Months</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <label for="LoanAmount">Loan Amount</label>
                            <input type="number" class="WhitePlaceHolder" id="LoanAmount" name="LoanAmount" 
                                    v-model="Amount" v-bind:min="MinAmount" v-bind:max="MaxAmount" v-on:input="this.Debounce()" placeholder="">
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <label for="MonthlyPayment">
                                Monthly Payment:
                                <div v-if="Loading" class="spinner-border spinner-border-sm"></div>
                            </label>
                            <input type="number" class="WhitePlaceHolder" id="MonthlyPayment" name="MonthlyPayment" v-model="MonthlyPayment" placeholder="AWG 0.00" readonly="readonly">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-12">
                        <div class="form-group form-btn">
                            <!-- <router-link :to="{ name: 'ApplyPage', params: { LoanAmount: this.Amount, Loan_Id: this.LoanType, MonthPeriod: this.MonthSelectedOption, MonthPayment: this.MonthlyPayment } }" class="btn style2 w-100 d-block">Apply Now</router-link> -->
                            <button class="btn style2 w-100 d-block" v-on:click="this.RedirectToApply()">Apply Now</button>
                        </div>
                    </div>

                    <div class="col-lg-12" style="margin-top: 20px;">
                        <p>
                            Maximum APR% Personal Loans: <span>27%</span>  <br />    
                        </p>
                        <p>
                            Maximum APR% Car Loans: <span>14%</span> <br />
                        </p>
                        <p>
                            Loan Period: <span>12-60 Months</span>
                        </p>
                        <p>
                            Review our
                            <router-link to="/terms-of-service" class="link style1">Terms and Policies</router-link>
                        </p>
                    </div>

                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

export default {
    name: 'Chargecalculator',
    props: ['loanType'],
    data() {
        return {
            LoanType: null,
            Amount: 3500,
            MonthsArray: [],
            MonthSelectedOption: null,
            MinAmount: null,
            MaxAmount: null,
            MonthlyPayment: 0,
            Loading: false,
            // UrlServerIP: 'http://185.144.158.27:',
            UrlServerIP: 'https://partner.madworksglobal.com',
            UrlServerPORT: ''
        }
    },
    mounted() {

        //Get loanType
        this.LoanType = this.loanType;

        //GetMin And MaxAmount
        this.GetMinAndMaxAmount();

        //Calculate MontlyPayment and APR

    },
    methods: {
        GetMinAndMaxAmount() {
            axios.get(`${this.UrlServerIP}${this.UrlServerPORT}/Api/Calculator/GetLoanRange?LoanType=${this.LoanType}`)
            .then(response => {
                if(response.status == 200){
                    var result = response.data;
                    this.MinAmount = result.minAmount;
                    this.MaxAmount = result.maxAmount;
                    this.Amount = result.minAmount;

                    //GetMonths
                    this.GetMonths();

                    //GetMonthlyPayment
                    if(this.MonthSelectedOption != null)
                        this.GetMonthlyPayment();
                }
                else{
                    console.log("An error has occurred in the GetMonthsByLoanType EndPoint.");
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        GetMonths(){
            axios.get(`${this.UrlServerIP}${this.UrlServerPORT}/Api/Calculator/GetMonthsByLoanType?Amount=${this.Amount}&LoanType=${this.LoanType}`)
            .then(response => {
                if(response.status == 200){
                    this.MonthsArray = response.data;
                    this.MonthSelectedOption = response.data[0];
                    this.GetMonthlyPayment();
                }
                else{
                    console.log("An error has occurred in the GetMonthsByLoanType EndPoint.");
                }

            })
            .catch(error => {
                console.error(error);
            });
        },
        GetMonthlyPayment(){
            this.Loading = true;
            axios.get(`${this.UrlServerIP}${this.UrlServerPORT}/Api/Calculator/GetMonthtlyPayment?Amount=${this.Amount}&Months=${this.MonthSelectedOption}&LoanType=${this.LoanType}`)
            .then(response => {
                if(response.status == 200){
                    var result = response.data;
                    this.MonthlyPayment = result.amount;
                    this.Loading = false;
                }
                else{
                    console.log("An error has occurred in the GetMonthsByLoanType EndPoint.");
                    this.Loading = false;
                }
            })
            .catch(error => {
                console.error(error);
                this.Loading = false;
            });
        },
        LaunchIziToast(type, msg){
            if (type == "success") {
                iziToast.success({
                    title: 'Success',
                    message: msg,
                    backgroundColor: '#6BD6D3',
                });
            }
            else if (type == "info") {
                iziToast.info({
                    title: 'Attention!',
                    message: msg,
                    backgroundColor: '#6BD6D3',
                });
            }
            else if (type == "warning") {
                iziToast.warning({
                    title: 'Attention!',
                    message: msg,
                    backgroundColor: '#FFE783',
                });
            }
            else if (type == "danger") {
                iziToast.danger({
                    title: 'Error!',
                    message: msg,
                });
            }
        },
        RedirectToApply() {
            let LoanTypeDescription = this.LoanType == 1 ? "Personal" : "Vehicle";
            if(this.Amount < this.MinAmount){
                this.LaunchIziToast("warning", `Our ${LoanTypeDescription} Loans start at AWG ${this.DelimiterMiles(this.MinAmount)}.`);
                this.Amount = this.MinAmount;
                this.GetMonthlyPayment();
            }
            else if(this.Amount > this.MaxAmount){
                this.LaunchIziToast("warning", `You have exceeded the maximum loan amount. Our ${LoanTypeDescription} Loans go up to ${this.DelimiterMiles(this.MaxAmount)}.`);
                this.Amount = this.MaxAmount;
                this.GetMonthlyPayment();
            }
            else {
                this.$router.push({
                    name: 'ApplyPage',
                    params: {
                        LoanAmount: this.Amount, 
                        Loan_Id: this.LoanType, 
                        MonthPeriod: this.MonthSelectedOption, 
                        MonthPayment: this.MonthlyPayment
                    }
                });
            }
        },
        Debounce(){
            this.Loading = true;
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                if(this.Amount >= this.MinAmount && this.Amount <= this.MaxAmount){
                    this.GetMonths();
                    this.GetMonthlyPayment();   
                }  
                else{
                    let LoanTypeDescription = this.LoanType == 1 ? "Personal" : "Vehicle";
                    if(this.Amount < this.MinAmount){
                        this.LaunchIziToast("warning", `Our ${LoanTypeDescription} Loans start at AWG ${this.DelimiterMiles(this.MinAmount)}.`);
                        this.Amount = this.MinAmount;
                        this.GetMonths();
                        this.GetMonthlyPayment();
                    }
                    else if(this.Amount > this.MaxAmount){
                        this.LaunchIziToast("warning", `You have exceeded the maximum loan amount. Our ${LoanTypeDescription} Loans go up to ${this.DelimiterMiles(this.MaxAmount)}.`);
                        this.Amount = this.MaxAmount;
                        this.GetMonths();
                        this.GetMonthlyPayment();
                    }
                }
            }, 1500);
        },
        DelimiterMiles(numero) {
            return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}

</script>