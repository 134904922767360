<template>
    <div class="feature-wrap pt-100 pb-75">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <!-- <span>Our requirements</span> -->
                <h2 class="text-black header-blue-tf-color">Our requirements</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img style="padding: 3px !important;" src="../../assets/images/feature/1-Icon-Employed.png" alt="Image"></span>
                                <h3 class="header-blue-tf-color">Employed Full-Time</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img style="padding: 3px !important;" src="../../assets/images/feature/2-Icon-Age.png" alt="Image"></span>
                                <h3 class="header-blue-tf-color">Age between 21 and 63 years</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img style="padding: 3px !important;" src="../../assets/images/feature/3-Icon-Citizenship.png" alt="Image"></span>
                                <h3 class="header-blue-tf-color">Aruban citizenship or minimum 5 work permits</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>