<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Personal Loan" />
            <PersonalLoan />
            <Chargecalculator :loanType="1" />
            <Feature />
            <!-- <Testimonials /> -->
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import PersonalLoan from '../Services/PersonalLoan'
import Chargecalculator from '../HomeOne/Chargecalculator'
import Feature from '../HomeOne/Feature'
// import Testimonials from '../HomeOne/Testimonials'
import Footer from '../Layouts/Footer'

export default {
    name: 'PersonalLoanPage',
    components: {
        Navbar,
        PageTitle,
        PersonalLoan,
        Chargecalculator,
        Feature,
        // Testimonials,
        Footer,
    }
}
</script>
