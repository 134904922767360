<template>
    <div class="shopping-wrap ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="shopping-content">
                        <img src="../../assets/images/shopping/shopping-shape-1.png" alt="Image" class="shopping-shape-one moveHorizontal">
                        <img src="../../assets/images/shopping/shopping-shape-2.png" alt="Image" class="shopping-shape-two bounce">
                        <div class="content-title style1 ">
                            <span>Purchase the car of your dreams.</span>
                            <h2 class="header-blue-tf-color">Financing Information</h2>
                            <p>
                                We make it possible to purchase the car of your dreams! Use our online car loan calculator to find the right loan for you. At Total Finance, we are here to support you with the purchase of your new vehicle. Calculate and submit your application online in three easy steps and within 48 hours we will review it and get back to you!
                            </p>
                        </div>
                        <!-- <ul class="content-feature-list list-style">
                            <li><i class="ri-check-double-line"></i>(Add reason).</li>
                            <li><i class="ri-check-double-line"></i>(Add reason).</li>
                            <li><i class="ri-check-double-line"></i>(Add reason).</li>
                        </ul> -->
                        <router-link :to="{ name: 'HomeOnePage', params: { ToCalculator: true } }" class="btn style1">Apply Now</router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="shopping-img-wrap">
                        <img src="../../assets/images/service/car-loan.png" alt="Image">
                        <img src="../../assets/images/shopping/shopping-shape-3.png" alt="Image" class="shopping-shape-three">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarLoan'
}
</script>