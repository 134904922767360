<template>
    <div class="referral">
        <iframe id="JotFormIFrame-243224090017849" title="REFERI Y RICIBI" onload="window.parent.scrollTo(0,0)"
                allowtransparency="true" allow="geolocation; microphone; camera; fullscreen" src="https://form.jotform.com/Total_Marketing/referi-y-ricibi"
                frameborder="0" style="min-width:100%;max-width:100%;height:539px;border:none;" scrolling="no">
        </iframe>
        <!-- <script></script> -->
    </div>
</template>

<style>
.referral {
    width: 100%;
}
</style>
<script>
export default {
    name: 'Referral'
}

window.jotformEmbedHandler("iframe[id='JotFormIFrame-243224090017849']", "https://form.jotform.com/")
</script>