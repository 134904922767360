<template>
    <div class="faq-wrap ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="faq-img-wrap">
                        <img src="../../assets/images/faq-1.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="accordion style2" id="accordionExample">

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                    aria-expanded="true" aria-controls="collapseOne">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    1. Who is eligible for a loan?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p><i class="flaticon-next"></i>Two years full-time employment at stable job.</p>
                                        <p><i class="flaticon-next"></i>Age between 21 and 63 years.</p>
                                        <p><i class="flaticon-next"></i>Aruban citizenship or minimum 5 working permit.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    2. Which documents are needed to start the loan request?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse "
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p><i class="flaticon-next"></i>Last 2 monthly pay slips (last 4 pay slips if paid bi-weekly).</p>
                                    <p><i class="flaticon-next"></i>Extract Census (Awg 5,-) not older than 3 months.</p>
                                    <p><i class="flaticon-next"></i>Address Confirmation (Utility Bill - Setar, WEB, ELMAR) not older than 3 months.</p>
                                    <p><i class="flaticon-next"></i>Valid I.D. (Issued by Aruban entity).</p>
                                    <p><i class="flaticon-next"></i>For joint loan, the same documents are applicable.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                    aria-expanded="false" aria-controls="collapseThree">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    3. What is the maximum loan amount?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse"
                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p><i class="flaticon-next"></i>The loan amount depends on different factors, including the income of the client and the purpose of the loan. Our loans start at AWG 2,500. Please contact us at 525-3400 or WhatsApp 594-2167 for more information.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingfour">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapsefour"
                                    aria-expanded="true" aria-controls="collapsefour">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    4. What is the duration of a loan?
                                </button>
                            </h2>
                            <div id="collapsefour" class="accordion-collapse collapse "
                                aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p><i class="flaticon-next"></i>The duration of a loan depends on the amount of the loan, together with other factors. The loans start at 12 months and can go up to 60 months.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingfive">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapsefive"
                                    aria-expanded="true" aria-controls="collapsefive">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    5. How long does an application take?
                                </button>
                            </h2>
                            <div id="collapsefive" class="accordion-collapse collapse "
                                aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p><i class="flaticon-next"></i>An application for a loan takes between 2-3 business days if every required document is in place.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingsix">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapsesix"
                                    aria-expanded="true" aria-controls="collapsesix">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    6. What types of payment methods are available?
                                </button>
                            </h2>
                            <div id="collapsesix" class="accordion-collapse collapse "
                                aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p><i class="flaticon-next"></i>Standing order through a local bank.</p>
                                        <p><i class="flaticon-next"></i>Online Transfer.</p>
                                        <p><i class="flaticon-next"></i>POS Payment at Rutena, Noord or Paradera branches.</p>
                                        <p><i class="flaticon-next"></i>Cash Payment at Rutena, Noord or Paradera branches.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingseven">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                    aria-expanded="true" aria-controls="collapseseven">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    7. Why does Total Finance sometimes request a guarantor?
                                </button>
                            </h2>
                            <div id="collapseseven" class="accordion-collapse collapse "
                                aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p><i class="flaticon-next"></i>Depending on the financial situation of the borrower, a request for a guarantor can be made to cover any additional risks run by Total Finance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingeight">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseeight"
                                    aria-expanded="true" aria-controls="collapseeight">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    8. What are the office hours?
                                </button>
                            </h2>
                            <div id="collapseeight" class="accordion-collapse collapse "
                                aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p><i class="flaticon-next"></i>Our branches at Rutena Mall, Noord and Paradera are open Monday through Friday nonstop from 8:00am to 5:00pm.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq'
}
</script>