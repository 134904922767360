<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <img
        src="../../assets/images/footer-shape-1.png"
        alt="Image"
        class="footer-shape-one"
      />
      <img
        src="../../assets/images/footer-shape-2.png"
        alt="Image"
        class="footer-shape-two"
      />
      <div class="row pt-100 pb-75">

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img width="100" src="../../assets/images/NewLogo-White.png" alt="Image" />
            </router-link>
            <p class="comp-desc">
              Simple, efficient and accessible financing. Let us help you achieve your goals.
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://www.facebook.com/totalaruba">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/totalaruba">
                    <i class="ri-instagram-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/totalaruba">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://wa.me/2975942167?text=Hi%2C%20How%20can%20we%20help%20you%3F">
                    <i class="ri-whatsapp-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Our Company</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/about">
                  <i class="flaticon-next"></i>
                  About us
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Contact
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Privacy policy
                </router-link>
              </li>
              <li>
                <router-link to="/terms-of-service">
                  <i class="flaticon-next"></i>
                  Algemene Voorwaarden
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xl-3 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Products</h3>
            <ul class="footer-menu list-style">

              <li>
                <router-link to="/personal-loan">
                  <i class="flaticon-next"></i>
                  Personal Loan
                </router-link>
              </li>
              <li>
                <router-link to="/car-loan">
                  <i class="flaticon-next"></i>
                  Car Loan
                </router-link>
              </li>

            </ul>
          </div>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Locations</h3>
            <ul class="footer-menu list-style">

              <li>
                <a href="javascript:void(0)">
                  <i class="flaticon-location"></i> Adriaan Laclé Blvd. #28 Lok. 4, Oranjestad
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="flaticon-location"></i> Paradera #176 Lok 2-3, Paradera
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="flaticon-location"></i> Noord #23A, Noord
                </a>
              </li>

            </ul>
            <!-- <p class="newsletter-text">
              Tablished fact that a reader will be distrated by the readable
              content
            </p>
            <form @submit.prevent class="newsletter-form">
              <input type="email" placeholder="Your Email" />
              <button type="button">Subscribe</button>
            </form> -->
          </div>
        </div>

      </div>
    </div>
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} Total. All Rights
        Reserved By
        <a href="javascript:void(0)">Total</a>
      </p>
      <p>
        Handcrafted By
        <a href="https://qwihi.com" target="_blank">Qwihi</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
