<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Car Loan" />
            <CarLoan />
            <Chargecalculator :loanType="2" />
            <Feature />
            <!-- <Testimonials /> -->
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import CarLoan from '../Services/CarLoan'
import Chargecalculator from '../HomeOne/Chargecalculator'
import Feature from '../HomeOne/Feature'
// import Testimonials from '../HomeOne/Testimonials'
import Footer from '../Layouts/Footer'

export default {
    name: 'CarLoanPage',
    components: {
        Navbar,
        PageTitle,
        CarLoan,
        Chargecalculator,
        Feature,
        // Testimonials,
        Footer,
    }
}
</script>
