<template>
    <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link to="/service-details">Fraud Detection</router-link></h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-computer"></i></span>
                                <h3><router-link to="/service-details">Account Updater</router-link></h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-loan-1"></i></span>
                                <h3><router-link to="/service-details">Payment Invoice</router-link></h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-secure-shield"></i></span>
                                <h3><router-link to="/service-details">Secure Payment</router-link></h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-mortarboard"></i></span>
                                <h3><router-link to="/service-details">Education Loan</router-link></h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-loan"></i></span>
                                <h3><router-link to="/service-details">Home Loan</router-link></h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet dolor alut const slice elit aliquid dolor ametin perfer endis velit sed fuga volup tation sit praising pain.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="page-nav list-style mt-20">
                <li><a href="/services-two"><i class="flaticon-back"></i></a></li>
                <li><a class="active" href="/services-two">1</a></li>
                <li><a href="/services-two">2</a></li>
                <li><a href="/services-two">3</a></li>
                <li><a href="/services-two"><i class="flaticon-next-1"></i></a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>