<template>
    <div class="about-wrap style1 ptb-100">
        <div class="container">
            <!-- <div class="row gx-5 align-items-center"> -->
            <div class="row align-items-center">
                <!-- <div class="col-lg-6">
                    <div class="about-img-wrap">
                        <img src="../../assets/images/about/about-shape-1.png" alt="Image" class="about-shape-one bounce">
                        <img src="../../assets/images/about/about-shape-2.png" alt="Image" class="about-shape-two moveHorizontal">
                        <img src="../../assets/images/about/about.png" alt="Image" class="about-img">
                    </div>
                </div> -->
                <div class="col-lg-12">
                    <div class="about-content">
                        <img src="../../assets/images/about/about-shape-3.png" alt="Image" class="about-shape-three">
                        <div class="content-title style1">
                            <span>Get to know us</span>
                            <h2 class="header-blue-tf-color">Let's get to know each other!</h2>
                            <p>Total Finance is a locally owned company invested in the local community of Aruba. We have over 40 years experience in the lending market. With 3 branches on the island, our staff strives to give you the best and quickest service when it comes to your financing needs. Our company has helped over 10,000 clients reach their financial goals. Contact us to find out how we can be of service to you!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmartBanking'
}
</script>