<template>
    <div class="terms-wrap ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">ALGEMENE VOORWAARDEN</h3>
                        <ol>
                            <li>De Kredietnemer garandeert dat hij bevoegd is om de overeenkomst van geldlening met Mack’s Total Finance VBA (hierna: Total Finance) te sluiten.</li>
                            <li>In verband met de overeenkomst kiest de Kredietnemer woonplaats op het adres dat staat vermeld in de overeenkomst van geldlening. Als de Kredietnemer verhuist moet hij/zij de nieuwe woonplaats schriftelijk aan Total Finance doorgeven. Zolang dat niet gebeurt blijft de Kredietnemer woonplaats houden op het laatste bij Total Finance bekende adres. Total Finance mag er ook altijd vanuit gaan dat de Kredietnemer woont op het adres waarop hij/zij staat ingeschreven in het bevolkingsregister (Censo).</li>
                            <li>Als de lening is verstrekt aan meer dan een kredietnemer zijn alle Kredietnemers hoofdelijk verbonden. Alle Kredietnemers zijn tegenover Total Finance hoofdelijk voor het geheel verbonden voor alle verplichtingen, nu en in de toekomst, die voortvloeien uit de overeenkomst van geldlening voor elke (andere) Kredietnemer. Elke Kredietnemer doet afstand van alle verweermiddelen en rechten aan hoofdelijk verbondenen toekomend. Als subrogatie optreedt bij betaling door één of meer van de Kredietnemers, zal deze subrogatie niet benut mogen worden in het nadeel van Total Finance. De gerechtigde tot deze subrogatie stelt de regresvordering achter ten opzichte van die van Total Finance.</li>
                            <li>De Kredietnemer heeft geen recht op verrekening (aftrekken) van zijn betalingsverplichting met een eventuele vordering van hem op Total Finance. Hij mag zijn betalingsverplichting ook niet opschorten (uitstellen). Total Finance heeft steeds het recht om betalingen aan de Kredietnemer en/of afboekingen op de vordering op de Kredietnemer die het gevolg zijn van een administratieve vergissing of een rekenfout van Total Finance te corrigeren.</li>
                            <li>Behalve op volledige schadevergoeding kan Total Finance op de Kredietnemer in ieder geval aanspraak maken op betaling van een boete van Afl. 25,‐‐ voor elke termijn die niet op tijd is betaald. Als Total Finance tot incasso (het eisen van betaling) overgaat is de Kredietnemer aansprakelijk voor alle daarmee verband houdende kosten. Het bedrag daarvan wordt vastgesteld op Afl.15,‐‐ voor de eerste incassobrief, Afl. 50,‐‐ voor de tweede incassobrief enAfl. 75,‐‐ voor de derde incassobrief. Als de vordering in handen wordt gesteld van de afdeling Bijzonder Beheer (Special Assets) is de Kredietnemer 10% van het dan openstaande bedrag aan incassokosten verschuldigd. Total Finance is niet verplicht incassobrieven te sturen voordat zij een incassoprocedure bij het Gerecht in Eerste Aanleg start. Total Finance is steeds gerechtigd betaling van daadwerkelijk gemaakte buitengerechtelijke incasso te vorderen.</li>
                            <li>Betalingen worden in volgorde eerst in mindering gebracht op de openstaande boete, de kosten en de rente en pas daarna op de hoofdsom.</li>
                            <li>Als een of meer maandtermijnen of de boete niet of niet op tijd betaald wordt, heeft Total Finance het recht om de overeenkomst onmiddellijk te ontbinden en het restant van de hoofdsom, de dan verschuldigde rente, de kosten en de boete op te eisen, te vermeerderen met de in de overeenkomst van geldlening overeengekomen rente, steeds over het restant van het totaal van die bedragen vanaf de dag dat dit bedrag wordt opgeëist totdat alles is betaald. Total Finance kan bovendien schadevergoeding eisen voor zover zij meer schade lijdt.</li>
                            <li>Daarnaast heeft Total Finance het recht om de overeenkomst op te zeggen in de volgende gevallen: als er beslag wordt gelegd op vermogen van de Kredietnemer, als het faillissement van de Kredietnemer wordt aangevraagd of hij/zij surseance van betaling aanvraagt, als de Kredietnemer feitelijk geen woonplaats in Aruba meer heeft, als de Kredietnemer in strafrechtelijke verzekering wordt gesteld, als ten aanzien van de Kredietnemer lijfsdwang wordt toegepast, als de Kredietnemer onder curatele wordt geplaatst of het vermogen geheel of deels onder bewind wordt gesteld, als de Kredietnemer Total Finance bewust verkeerd heeft ingelicht over omstandigheden waarvan de Kredietnemer redelijkerwijze moet weten dat die van belang zijn voor de beoordeling van de kredietwaardigheid van de Kredietnemer; als de Kredietnemer niet binnen een redelijke termijn voldoet aan het verstrekken van door Total Finance gevraagde (aanvullende) zekerheid in verband met de betalingsverplichting. Opzegging van de overeenkomst leidt tot dezelfde verplichting van de Kredietnemer als ontbinding daarvan.</li>
                            <li>Als de overeenkomst wordt beëindigd anders dan op grond van de hiervoor genoemde gevallen is de Kredietnemer een vergoeding verschuldigd die gelijk is aan een derde (1/3) van de op het moment van beëindiging niet verdiende kredietvergoeding met een minimum van zes (6) maanden of zoveel minder als de overeenkomst zonder voortijdige beëindiging daarvan gelopen zou hebben.</li>
                            <li>In het geval van overlijden van de Kredietnemer later dan zes maanden na ondertekening van dit contract maar voor het bereiken van de 65-jarige leeftijd verleent Total Finance kwijtschelding voor het volledige dan nog uitstaande bedrag. Total Finance is niet verplicht tot kwijtschelding als de Kredietnemer informatie heeft achtergehouden waarvan hij redelijkerwijs moest begrijpen dat die voor Total Finance van belang was voor het beoordelen van het overlijdensrisico. Total Finance is ook niet verplicht tot kwijtschelding als de Kredietnemer een of meer van de verplichtingen uit deze overeenkomst niet is nagekomen.</li>
                            <li>Total Finance is niet verplicht om informatie te verschaffen over de lening behalve als zij daartoe volgens de wet verplicht is en als de Kredietnemer daar zelf om vraagt. Total Finance behoudt zich het recht voor om, spontaan of daarnaar gevraagd, informatie te verschaffen over deze lening aan banken of andere (rechts)personen aan wie door een van de Centrale Banken binnen het Koninkrijk der Nederlanden toestemming of ontheffing van een verbod is verleend om geld uit te lenen of aan de Centrale Bank zelf. De Kredietnemer verleent Total Finance toestemming om informatie in te winnen over de financiële betrouwbaarheid van de Kredietnemer.</li>
                            <li>Het bedrag dat de Kredietnemer aan Total Finance schuldig is blijkt uit de administratie van Total Finance tenzij de Kredietnemer bewijst dat een ander bedrag verschuldigd is. De Kredietnemer moet informatie die hij/zij van Total Finance krijgt goed controleren en Total Finance binnen een maand schriftelijk waarschuwen als die informatie volgens de Kredietnemer niet klopt, anders komen de kosten van een onderzoek naar de juistheid van die informatie voor rekening van de Kredietnemer. Total Finance mag voor de (geschatte) kosten van zo’n onderzoek een voorschot van de Kredietnemer vragen.</li>
                            <li>Total Finance kan verlangen dat voor de communicatie tussen haar en de Kredietnemer gebruikt wordt gemaakt van door Total Finance voorgeschreven formulieren. Total Finance behoudt zich het recht voor geen uitvoering te geven aan een opdracht waarvoor geen gebruik is gemaakt van een door haar voorgeschreven formulier. Total Finance is niet aansprakelijk voor schade die ontstaat doordat de Kredietnemer mondeling met Total Finance communiceert of geen gebruik maakt van door Total Finance voorgeschreven formulieren.</li>
                            <li>Als enige bepaling in de overeenkomst van geldlening, deze algemene voorwaarden of een andere afspraak die verband houdt met de overeenkomst van geldlening ongeldig is blijven de andere bepalingen of afspraken geldig en wordt de ongeldige voorwaarde of afspraak vervangen door een die voor het doel daar zo dicht mogelijk in de buurt komt maar wel geldig is.</li>
                            <li>Op deze overeenkomst en gebeurtenissen in samenhang daarmee is Arubaans recht van toepassing. Het Gerecht in Eerste Aanleg van Aruba is de enige rechter die in eerste aanleg op een geschil tussen partijen mag beslissen. Alleen de Nederlandse tekst van deze overeenkomst is voor de uitleg daarvan doorslaggevend.</li>
                        </ol>
                    </div>
                    
                    <br />
                    <br />
                    <div class="single-terms">
                        <h5 class="header-blue-tf-color"> — English Version — </h5>
                    </div>
                    <br />
                    <br />

                    <div class="single-terms">
                        <h3 class="header-blue-tf-color">TERMS AND CONDITIONS</h3>
                        <ol>
                            <li>The Borrower warrants that it is authorized to enter into the money loan agreement with Mack’s Total Finance VBA (hereinafter referred to as Total Finance).</li>
                            <li>In connection with the agreement, the Borrower elects domicile at the address specified in the money loan agreement. If the Borrower moves, he/she must provide the new residence in writing to Total Finance. Until that happens, the Borrower remains domiciled at the last address known to Total Finance. Total Finance may also always assume that the Borrower lives at the address at which he/she is registered in the population register (Censo).</li>
                            <li>If the loan is made to more than one Borrower, all Borrowers are jointly and severally liable. All Borrowers are jointly and severally liable to Total Finance for all obligations, now and in the future, arising under the money loan agreement for each (other) Borrower. Each Borrower waives all defenses and rights accruing to joint and several. If subrogation occurs upon payment by one or more of the Borrowers, such subrogation shall not be utilized to the detriment of Total Finance. The person entitled to such subrogation shall subordinate the recourse claim to that of Total Finance.</li>
                            <li>The Borrower is not entitled to set off (deduct) his payment obligation against any claim of his against Total Finance. Nor may he suspend (postpone) his payment obligation. Total Finance is always entitled to correct payments to the Borrower and/or debits to the receivable from the Borrower that are the result of an administrative error or calculation error by Total Finance.</li>
                            <li>In addition to full damages, Total Finance may in any case claim from the Borrower a penalty of Afl. 25 for each installment not paid on time. If Total Finance proceeds to collection (demanding payment), the Borrower shall be liable for all related costs. The amount thereof is set at Afl.15,– for the first collection letter, Afl. 50,– for the second collection letter and Afl. 75,– for the third collection letter. If the claim is placed in the hands of the Special Assets Department, the Borrower will owe 10% of the then outstanding amount in collection costs. Total Finance is not obliged to send collection letters before initiating collection proceedings at the Court of First Instance. Total Finance is always entitled to claim payment of actual out-of-court collection costs incurred.</li>
                            <li>Payments shall be deducted in order first from the outstanding penalty, costs and interest and only then from the principal amount.</li>
                            <li>If one or more monthly instalments or the penalty is not paid or not paid on time, Total Finance is entitled to terminate the agreement immediately and to claim the remainder of the principal sum, the interest then due, the costs and the penalty, plus the interest agreed in the loan agreement, always on the remainder of the total of those amounts from the day such amount is claimed until all is paid. Total Finance may also claim damages to the extent that it suffers more damages.</li>
                            <li>In addition, Total Finance is entitled to terminate the agreement in the following cases: if the Borrower’s assets are attached, if the Borrower’s bankruptcy is filed for or he/she applies for suspension of payments, if the Borrower is no longer actually domiciled in Aruba, if the Borrower is placed in criminal insurance, if with respect to the Borrower body coercion is applied, if the Borrower is placed under guardianship or all or part of its assets are placed under administration, if the Borrower has knowingly misinformed Total Finance of circumstances of which the Borrower should reasonably know that they are relevant to the assessment of the Borrower’s creditworthiness; if the Borrower does not comply within a reasonable period of time with the provision of (additional) security requested by Total Finance in connection with the payment obligation. Termination of the agreement shall result in the same obligation of the Borrower as termination thereof.</li>
                            <li>If the agreement is terminated other than pursuant to the foregoing, the Borrower shall be liable for compensation equal to one-third (1/3) of the unearned credit fee at the time of termination with a minimum of six (6) months or so much less as the agreement would have run in the absence of early termination thereof.</li>
                            <li>In the event of the Borrower’s death later than six months after signing this Contract but before reaching the age of 65, Total Finance shall grant remission for the entire then outstanding amount. Total Finance is not obliged to grant remission if the Borrower has withheld information that the Borrower should reasonably understand was important to Total Finance in assessing the risk of death. Total Finance is also not obliged to remit if the Borrower has failed to comply with one or more of its obligations under this Agreement.</li>
                            <li>Total Finance is not obliged to provide information about the loan except if it is required to do so by law and if requested by the Borrower. Total Finance reserves the right, spontaneously or upon request, to provide information about this loan to banks or other (legal) persons to whom one of the Central Banks within the Kingdom of the Netherlands has granted permission or exemption from a prohibition to lend money or to the Central Bank itself. The Borrower authorizes Total Finance to obtain information about the Borrower’s financial reliability.</li>
                            <li>The amount owed by the Borrower to Total Finance is evidenced by Total Finance’s records unless the Borrower proves that a different amount is owed. The Borrower must properly verify information received from Total Finance and notify Total Finance in writing within one month if, in the Borrower’s opinion, such information is incorrect, otherwise the costs of an investigation into the accuracy of such information shall be borne by the Borrower. Total Finance may request an advance from the Borrower for the (estimated) costs of such investigation.</li>
                            <li>Total Finance may require the use of forms prescribed by Total Finance for communications between it and the Borrower. Total Finance reserves the right not to execute an order for which a form prescribed by it has not been used. Total Finance shall not be liable for damages arising from the Borrower’s oral communication with Total Finance or failure to use forms prescribed by Total Finance.</li>
                            <li>If any provision in the money loan agreement, these general terms and conditions or any other agreement related to the money loan agreement is invalid, the other provisions or agreements shall remain valid and the invalid condition or agreement shall be replaced by one that is as close as possible to it for the purpose but valid.</li>
                            <li>This agreement and events in connection therewith shall be governed by Aruban law.</li>
                        </ol>
                    </div>

                    <div class="single-terms">
                        <p>Governing Law. The Court of First Instance of Aruba shall be the sole judge of first instance to decide any dispute between the parties. Only the Dutch text of this agreement shall be decisive for its interpretation. </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsOfService'
}
</script>