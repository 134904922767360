<template>
    <div class="security-wrap ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center">
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-6 col-md-6 col-6">
                  <div class="security-content">
                    <div class="feature-item-wrap">
                        <div class="feature-item">
                            <div class="feature-icon">
                                <img src="../../assets/images/about/Mission.png" alt="Image">
                            </div>
                            <div class="feature-text">
                                <h2 class="header-blue-tf-color">Mission</h2>
                                <p>We offer financial alternatives that are accessible to the entire community, easy to manage, effective, and simple.</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-6">
                  <div class="security-content">
                    <div class="feature-item-wrap">
                        <div class="feature-item">
                            <div class="feature-icon">
                                <img src="../../assets/images/about/Mission.png" alt="Image">
                            </div>
                            <div class="feature-text">
                                <h2 class="header-blue-tf-color">Vision</h2>
                                <p>Provide innovative financial services to as many customers as possible in a socially responsible way.</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BankingSecurity'
}
</script>