<template>

    <section class="why-choose-wrap style1 pb-100 bg-bunting">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="wh-img-wrap">
                        <img src="../../assets/images/why-choose-us/circular-personal-loan.png" style="max-width: 60%;" alt="Image">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>Our Products</span>
                            <h2 class="header-blue-tf-color">Personal Loan</h2>
                            <p>From renovating your home, to planning your dream vacation, our personal loans help you reach your goals! Use our easy online calculator to find the perfect loan for your budget.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="why-choose-wrap style1 pb-100 bg-bunting">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>Our Products</span>
                            <h2 class="header-blue-tf-color">Car Loan</h2>
                            <p>Looking to purchase the car of your dreams? We make it possible! Use our online car loan calculator to find the right loan for you.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wh-img-wrap">
                        <img src="../../assets/images/why-choose-us/circular-car-loan.png" style="max-width: 60%;" alt="Image">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>