<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Apply for a Loan" />
            <Apply :LoanAmount="$route.params.LoanAmount" :Loan_Id="$route.params.Loan_Id" :MonthPeriod="$route.params.MonthPeriod" :MonthPayment="$route.params.MonthPayment" />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Apply from '../Apply/Apply'
import Footer from '../Layouts/Footer'

export default {
    name: 'ApplyPage',
    components: {
        Navbar,
        PageTitle,
        Apply,
        Footer,
    }
}
</script>