<template>
    <div class="shopping-wrap ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="shopping-content">
                        <img src="../../assets/images/shopping/shopping-shape-1.png" alt="Image" class="shopping-shape-one moveHorizontal">
                        <img src="../../assets/images/shopping/shopping-shape-2.png" alt="Image" class="shopping-shape-two bounce">
                        <div class="content-title style1 ">
                            <!-- <span>Make your dreams come true.</span> -->
                            <h2 class="header-blue-tf-color">Financing Information</h2>
                            <p>
                                From renovating your home, to planning your dream vacation, our personal loans help you reach your goals! Use our easy online calculator to find the perfect loan for your budget and receive an answer within 48 hours.
                            </p>
                        </div>
                        <!-- <ul class="content-feature-list list-style">
                            <li><i class="ri-check-double-line"></i>(Add reason).</li>
                            <li><i class="ri-check-double-line"></i>(Add reason).</li>
                            <li><i class="ri-check-double-line"></i>(Add reason).</li>
                        </ul> -->
                        <!-- <router-link to="/apply-loan" class="btn style1">Apply Now</router-link> -->
                        <!-- <router-link :to="{ name: 'ApplyPage', params: { LoanAmount: 2500, Loan_Id: 1, MonthPeriod: 18, MonthPayment: 166.89 } }" class="btn style1">Apply Now</router-link> -->
                        <router-link :to="{ name: 'HomeOnePage', params: { ToCalculator: true } }" class="btn style1">Apply Now</router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="shopping-img-wrap">
                        <img src="../../assets/images/service/personal-loan.png" alt="Image">
                        <img src="../../assets/images/shopping/shopping-shape-3.png" alt="Image" class="shopping-shape-three">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PersonalLoan'
}
</script>